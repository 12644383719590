<template>
    <div>
        <w-flex column justify-center align-center class="wrapper e404-bg">
            <div>
                <w-flex justify-center align-center class="wrapper">
                    <w-image :src="require('@/assets/logo.png')" :width="280" :height="280"></w-image>
                    <div class="e404 mr3">404</div>
                </w-flex>
            </div>
            <div class="text-center e404-contain-txt">
                
                <div class="title1 e404t1 ma5">Esta página no está disponible.</div>
                <div class="title3 e404t3 ma5"> Es posible que el enlace que seleccionaste <br> no funcione.</div>
                <w-button height="60" class="fill-width my5" route="/">VOLVER</w-button>
            </div>    
        </w-flex>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.e404-bg {
    height: 100vh;
    top: 0;
    bottom: 0;
    background-color: #000;
    background-image: url("@/assets/bg/1.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

.e404-contain-txt {
    background-color: rgba(5, 51, 73, 0.5) !important;
}

.e404 {
    font-size: 60px;
    color: #f89b22
}

.e404t1 {
    color: #f89b22
}

.e404t3 {
    color: #148da5
}
</style>