import { defineStore } from 'pinia'
import messagingService from '@/stores/services/messaging.services'

const PREFIX = 'efsadmin/'
const STORAGE_FMC = process.env.VUE_APP_NAME_FMC_STORAGE_APP;
const FMCToken = localStorage.getItem(STORAGE_FMC);
const NAME_APP = process.env.VUE_APP_APPLICATION_NAME;

export const useMessagingStore = defineStore("messaging", {
    state: () => ({
        fmcToken: FMCToken ? FMCToken : undefined,
        messagesList: []
    }),
    getters: {
        getFmcToken: (state) => state.fmcToken,
        getMessages: (state) => state.messagesList
    },
    actions: {
        setToken(data) {
            if (this.fmcToken) {
                localStorage.removeItem(STORAGE_FMC);
            }
            this.fmcToken = data;
            localStorage.setItem(STORAGE_FMC, data);
        },
        subscribe_to_group() {
            try {
                let dataApp = JSON.parse(localStorage.getItem(NAME_APP));
                let fcmToken = localStorage.getItem(STORAGE_FMC);
                let payload = {
                    token: fcmToken,
                    topic: dataApp.user.rol.name
                }
                return messagingService.post_service(payload, process.env.VUE_APP_NOTIFY_SUBSCRIBE_USER).then(() => {
                    return Promise.resolve(200);
                }, error => {
                    console.error(error)
                    return Promise.reject(error);
                })
            } catch (error) {
                console.error(error)
                return Promise.reject(error);
            }

        },
        unsuscribe_to_group() {
            try {
                let dataApp = JSON.parse(localStorage.getItem(NAME_APP));
                let fcmToken = localStorage.getItem(STORAGE_FMC);
                let payload = {
                    token: fcmToken,
                    topic: dataApp.user.rol.name
                }
                return messagingService.post_service(payload, process.env.VUE_APP_NOTIFY_UNSUBSCRIBE_USER).then(() => {
                    return Promise.resolve(200);
                }, error => {
                    console.error(error)
                    return Promise.reject(error);
                })
            } catch (error) {
                console.error(error)
                return Promise.reject(error);
            }
        },
        create(data) {
            try {
                let entity = `${PREFIX}invoice`
                return repositoryService.post(data, entity).then(
                    response => {
                        return Promise.resolve(response.status)
                    },
                    (e) => {
                        console.error(e)
                        return Promise.reject(false)
                    }
                );
            } catch (error) {
                console.error(error);
            }
        },
    }
})