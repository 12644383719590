import axios from 'axios';
import 'animate.css';
import Swal from 'sweetalert2'

const NAME_APP = process.env.VUE_APP_APPLICATION_NAME;

export default function setup(store, router) {

    axios.interceptors.request.use(function (config) {
        const token = JSON.parse(localStorage.getItem(NAME_APP));
        const fmc = localStorage.getItem(process.env.VUE_APP_NAME_FMC_STORAGE_APP)
        store.setLoader(true)
        if (token.access_token) {
            config.headers.Authorization = `Bearer ${token.access_token}`;
            config.headers.tokenUser = `${token.user.uakey}`;
        }
        if(fmc){
            config.headers.fmc = fmc
        }
        return config
    })

    axios.interceptors.response.use(
        response => {
            if (response.status === 200 || response.status === 201) {
                setTimeout(() => store.setLoader(false), 1200);
                return Promise.resolve(response);
            } else {
                setTimeout(() => store.setLoader(false), 1200);
                return Promise.reject(response);
            }
           
        }, error => {
            console.log("error ",error)
            let message = error.response.data.message ?  error.response.data.message: error
            if(message && message == "Unauthenticated."){
                Swal.fire({
                    icon: "error",
                    title: "Credenciales Vencias o Inválidas",
                    text: "Debere autenticarse nuevamente",
                    showClass: {
                        popup: `
                        animate__animated
                        animate__fadeInUp
                        animate__faster
                        `
                    },
                    hideClass: {
                        popup: `
                        animate__animated
                        animate__fadeOutDown
                        animate__faster
        `
                    },
                    customClass: {
                        container: "mySwal",
                    },
                    confirmButtonText: "Autenticarse",
                    
                }).then((result)=>{
                    if (result.isConfirmed) {
                        router.replace({ path: '/login' })
                    }
                });
            }
            else if(message){
                Swal.fire({
                    icon: "error",
                    title: "Error en ejecución",
                    text: message,
                    showClass: {
                        popup: `
                        animate__animated
                        animate__fadeInUp
                        animate__faster
                        `
                    },
                    hideClass: {
                        popup: `
                        animate__animated
                        animate__fadeOutDown
                        animate__faster
        `
                    },
                    customClass: {
                        container: "mySwal",
                    },
                });
            }
            setTimeout(() => store.setLoader(false), 1200);
            return Promise.reject(error);
        });
}