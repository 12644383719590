import axios from 'axios';

const API_URL = process.env.VUE_APP_API_FMC;
const fmc_axios = axios.create();

class MessagningService {
    get_messageUI(entity) {
        return fmc_axios.get(API_MESSAGES + PREFIX_MSG +  entity);
    }
    post_service(body, entity) {
        return fmc_axios.post(API_URL + entity, body);
    }

    post(data, entity) {
        return axios.post(API_MESSAGES + PREFIX_MSG +  entity, data);
    }

    get_my_message(entity) {
        return axios.get(API_MESSAGES + PREFIX_MSG +  entity);
    }

    update_entity(payload, entity){
        return axios.put(API_MESSAGES + PREFIX_MSG + entity, payload);
    }
}

export default new MessagningService();