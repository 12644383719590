import { createApp } from 'vue'
import App from './App.vue'
import { useCommonStore } from '@/stores/commons'
import { createPinia } from 'pinia'
import './registerServiceWorker'
import router from './router'
import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'
import 'sweetalert2/src/sweetalert2.scss'
import '@mdi/font/css/materialdesignicons.min.css'
import interceptor from './utils/interceptor';
import JsonExcel from "vue-json-excel3";


const pinia = createPinia()


createApp(App).use(WaveUI, {
  colors: {
    primary: '#348e91',
    secondary: '#f27528',
    accent: '#18b6d2',
    txtgray: '#7b7373',
    btntoolbar: '#15b2ce',
    excel: '#2d572c',
    error: '#f44336',
    info: '#2196f3',
    success: '#4caf50',
    warning: '#ff9800',
  },
  theme: 'light'
}).use(router).use(pinia).component("downloadExcel", JsonExcel).mount('#app')


const store = useCommonStore();
interceptor(store, router)
