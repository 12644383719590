import axios from 'axios';

const API_URL = process.env.VUE_APP_SERVICES_HOST

class RepositoryService{
    get(entity) {        
        return axios.get(`${API_URL}${entity}`);
    }

    post(payload, entity){
        return axios.post(`${API_URL}${entity}`, payload);
    }

    update(payload, entity){
        return axios.put(`${API_URL}${entity}`, payload);
    }

    delete(payload, entity){
        return axios.delete(`${API_URL}${entity}`,  { data: payload } );
    }

    upload(payload, entity){
        const config = { headers: { "Content-Type": "multipart/form-data" } };        
        return axios.post(`${API_URL}${entity}`, payload, config);
    }
}

export default new RepositoryService();
