import axios from 'axios';
import 'animate.css';
import Swal from 'sweetalert2'

const API_URL = process.env.VUE_APP_SERVICES_HOST
const unaxios = axios.create()

unaxios.interceptors.request.use(function (config) {
    //loaderStore.active()
    return config

}, function (err) {
    //loaderStore.desactive()
    return Promise.reject(err);
});
unaxios.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            //loaderStore.desactive()
            return Promise.resolve(response);
        } else {
            //loaderStore.desactive()
            return Promise.reject(response);
        }
    },
    error => {
        let message = error.response.data.error ? error.response.data.error : error
        //setTimeout(() => store.setLoader(false), 1200);
        Swal.fire({
            icon: "error",
            title: "Error en Autenticación",
            text: message,
            showClass: {
                popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                    `
            },
            hideClass: {
                popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
    `
            },
            customClass: {
                container: "mySwal",
            },
        });
        if (error && error.response && error.response.status) {
            switch (error.response.status) {
                case 400:
                    console.log("error 400 " + error.response.statusText);
                    break;
                case 401:
                    console.log("error 401 " + error.response.data.message);

                    break;
                case 403:
                    console.log("error 403 " + error.response.statusText);
                    break;
                case 404:
                    console.log("error 404 " + error.response.statusText);
                case 409:
                    console.log("error 409 " + error.response.data.message);
                    toastStore.error409(error.response.data.message)
                    break;
                case 500:
                    console.log("error 500 " + error.response.statusText);
                    break;
                case 502:
                    console.log("error 502 " + error.response.statusText);
            }
            //loaderStore.desactive()
            return Promise.reject(error.response);
        }
        else {
            console.log("else")
            //loaderStore.desactive()
        }
    }
);

class authService {
    async login(payload, entity) {
        return unaxios.post(`${API_URL}${entity}`, payload);
    }

    async me(payload, entity) {
        return axios.post(`${API_URL}${entity}`, {});
    }

    create(payload, entity) {
        payload.password_confirmation = payload.password
        return unaxios.post(`${API_URL}${entity}`, payload);
    }
}

export default new authService();