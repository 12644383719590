<template>
  <div class="toast">
    <div class="logo">
      <img src="https://controlpanel.efficientsolutions.com.mx/img/logo.c7e67252.png" class="tstlogo" alt="">
    </div>
    <div class="message">
      <div class="title">Notificacion del Sistema EFS</div>
      <div class="body">{{toast.message}}</div>
      <div>
        <w-button class="fill-width" bg-color="success" shadow @click="goLink()">Revisar</w-button>
      </div>
    </div>
  </div>
</template>
  
<script>
import { useToastStore } from '@/stores/toast.js';

export default {
  name: "toast",
  props: {
    toast: null,
  },
  data: () => ({
    store: useToastStore(),
    link: null,
  }),
  created() {
    setTimeout(() => {
      this.dismissToast();
    }, 7000);
    this.setData()
  },
  methods: {
    dismissToast() {
      this.store.clearToast(this.toast.title)
    },
    setData(){
      if(this.toast.title){
        this.link = this.toast.title.split('|')
      }
    },
    goLink(){
      this.$router.push(`/${this.link[0]}/${this.link[1]}`)
    }
  },
};
</script>

<style scoped>
.toast {
  display: flex;
  align-items: center;
  /* border: 1px solid #ccc; */
  border-left: 5px solid green;
  padding: 10px;
  background-color: #f5f5f5;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.toast .logo {
  position: relative;
  text-align: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #ddd;
  margin-right: 10px;
}
.tstlogo{
  width: 50px;
  height: 50px;
}

.toast .message {
  flex-grow: 1;
}

.toast .message .title {
  font-weight: bold;
  margin-bottom: 5px;
}

.toast .message .body {
  font-size: 14px;
}
</style>
  
<!-- <style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.c-toats {
  background-color: #fff;
}
</style> -->