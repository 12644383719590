<template>
  <div id="app">
    <Toasts class="toast" />
    <w-overlay v-model="loginShowOverlay" bg-color="rgba(35, 71, 129, 0.4)">
      <w-flex column align-center justify-center class="wrapper">
        <img src="./assets/animations/efs-animation.gif" id="image">
        <div class="lds-ellipsis mt-7">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </w-flex>
    </w-overlay>
    <w-overlay v-model="showOverlay" bg-color="rgba(35, 71, 129, 0.4)">
      <w-flex column align-center justify-center class="wrapper">
        <img src="./assets/animations/efs-animation.gif" id="image">
        <div class="lds-ellipsis mt-7">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </w-flex>
    </w-overlay>
    <w-app>
      <router-view />
    </w-app>
  </div>
</template>
<script>
import { app } from "./firebase";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { Toasts } from "@/components";

import { useCommonStore } from '@/stores/commons'
import { useAccountStore } from '@/stores/accounts'
import { useMessagingStore } from '@/stores/messaging'
import { useToastStore } from '@/stores/toast.js';
export default {
  components: {
    Toasts
  },
  computed: {
    showOverlay() {
      const store = useCommonStore();
      return store.isBussy
    },
    loginShowOverlay() {
      const lgStore = useAccountStore();
      return lgStore.isBussy
    }
  },
  created() {
    this.initMsg()

  },
  methods: {
    initMsg() {
      const fmcStore = useMessagingStore();
      const messaging = getMessaging(app);
      getToken(messaging, {
        vapidKey: "BCfxEPw_2ojE319J_0vuyDCz56V9g944AibOsHwwsKq7EjnKkBEeWfVExa94URBMlwv2_1PCuUPpljJ-0x0s9XM",
      }).then((currentToken) => {
        if (currentToken) {
          console.log("TOKEN ID ADMIN_INVOIVE FOR THIS BROWSER");
          console.log(currentToken);
          fmcStore.setToken(currentToken)
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      }).catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });

      onMessage(messaging, (payload) => {
        let tStore = useToastStore()
        if(payload.notification){
          tStore.showMessage(payload.notification)
        }
        else if(payload.data){
          tStore.showMessage(payload.data)
        }
        
        console.log('Message received. ', payload);

      });
    }
  }
}
</script>
<style>
#app {
  /* text-align: left; */
  top: 0;
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  font-size: 15px;
  line-height: 1.6;
  font-weight: 400;
  letter-spacing: .075em;
  color: #777777;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
}

.w-table>th {
  text-align: center !important;
}

.section-component {
  height: 100%;
}

.toats,
.dialog-z-index {
  z-index: 999999999999 !important;
}

.mySwal {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
}

.w-overlay {
  backdrop-filter: blur(10px);
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #f27528;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/* TRANSICIONES  */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

/* Input text */
/* Para navegadores basados en Webkit (como Chrome y Safari) */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Para Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* transiciones */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active en versiones anteriores a 2.1.8 */ {
  opacity: 0;
}
</style>
