import { createRouter, createWebHistory } from 'vue-router'
import LayouView from '../views/LayouView.vue'
import NotFoundView from '@/views/NotFoundView'
import { isAuthenticated } from '@/utils/validate'
import { useCommonStore } from '@/stores/commons.js'

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: LayouView,
    children: [
      {
        path: '',
        meta: { requiresAuth: true },
        name: 'Dashboard',
        component: () => import('../views/DashboardViews/DashboardAdmin.vue')
      },
      {
        path: 'administracion',
        name: 'Administración',
        component: () => import('@/views/AdminViews/index.vue'),
        meta: { title: 'Admnistración', icon: 'mdiAccounts', requiresAuth: true },
        children: [
          //DASHBOARD ADMIN | REGISTRO DE USUARIOS
          {
            path: '',
            name: 'Panel Gerencial',
            component: () => import('@/views/AdminViews/MainView.vue'),
          },
          {
            path: 'usuarios',
            name: 'Usuario sistema',
            component: () => import('../views/AccountViews/UsersView.vue')
          },
          {
            path: 'usuarios/registro',
            name: 'Registrar Usuario',
            component: () => import('../views/AccountViews/CreateView.vue')
          },
          //COMPAÑIAS
          {
            path: 'empresas',
            name: 'Lista Empresas',
            component: () => import('../views/CompanyViews/CompanyView.vue')
          },
          {
            path: 'empresas/registro',
            name: 'Registro Empresa',
            component: () => import('../views/CompanyViews/CreateView.vue')
          },
          {
            path: 'empresas/consulta/:uuid',
            name: 'Edición Empresa',
            component: () => import('../views/CompanyViews/EditView.vue')
          },
          //PERSONAS FISICAS
          {
            path: 'personas_fisicas',
            name: 'Lista Personas Fisicas',
            component: () => import('../views/PersonPhysicView/PersonView.vue')
          },
          {
            path: 'personas_fisicas/registro',
            name: 'Registro Personas Fisicas',
            component: () => import('../views/PersonPhysicView/CreateView.vue')
          },
          // {
          //   path: 'personas_fisicas/consulta/:uuid',
          //   name: 'Edición Personas Fisicas',
          //   component: () => import('../views/PersonPhysicView/EditView.vue')
          // },
          //CLIENTES
          {
            path: 'clientes',
            name: 'Lista Clientes',
            component: () => import('../views/ClientViews/ClientView.vue')
          },
          {
            path: 'clientes/registro',
            name: 'Registro Cliente',
            component: () => import('../views/ClientViews/CreateView.vue')
          },
          //ASISTENCIAS
          {
            path: 'asistencias',
            name: 'Lista Asistencia',
            component: () => import('@/views/AttendanceViews/ListView.vue')
          },
          //OPERACIONES DE CLIENTES
          {
            path: 'operacones',
            name: 'Lista de operaciones',
            component: () => import('@/views/TransactionView/ListView.vue')
          },
          {
            path: 'operacones/consulta/:uuid',
            name: 'Consulta Edicion',
            props: true,
            component: () => import('@/views/TransactionView/ReadEditView.vue')
          }
        ]
      },
      {
        path: 'catalogos',
        name: 'Catalogos',
        component: () => import('@/views/CatalogViews/index.vue'),
        meta: { title: 'Catálogos', icon: 'mdiAccounts', requiresAuth: true },
        children: [
          //DASHBOARD ADMIN | REGISTRO DE USUARIOS
          {
            path: '',
            name: 'Panel Catalogos',
            component: () => import('@/views/CatalogViews/MainView.vue'),
          },
          //USUARIOS
          {
            path: 'usuarios',
            name: 'Lista Usuarios',
            component: () => import('../views/CatalogViews/Accounts/ListView.vue')
          },
          {
            path: 'usuarios/registro',
            name: 'Registro Usuario',
            component: () => import('../views/CatalogViews/Accounts/CreateView.vue')
          },
          {
            path: 'usuarios/consulta/:uuid',
            name: 'Edición Usuario',
            component: () => import('../views/CompanyViews/EditView.vue')
          },
          //COMPAÑIAS
          {
            path: 'empresas',
            name: 'Lista Empresas',
            component: () => import('../views/CompanyViews/CompanyView.vue')
          },
          {
            path: 'empresas/registro',
            name: 'Registro Empresa',
            component: () => import('../views/CompanyViews/CreateView.vue')
          },
          {
            path: 'empresas/consulta/:uuid',
            name: 'Edición Empresa',
            props: true,
            component: () => import('../views/CompanyViews/EditView.vue')
          },
          //PERSONAS FISICAS
          {
            path: 'personas_fisicas',
            name: 'Lista Personas Fisicas',
            component: () => import('../views/PersonPhysicView/PersonView.vue')
          },
          {
            path: 'personas_fisicas/registro',
            name: 'Registro Personas Fisicas',
            component: () => import('../views/PersonPhysicView/CreateView.vue')
          },
          // {
          //   path: 'personas_fisicas/consulta/:uuid',
          //   name: 'Edición Personas Fisicas',
          //   component: () => import('../views/PersonPhysicView/EditView.vue')
          // },
          //CLIENTES
          {
            path: 'clientes',
            name: 'Lista Clientes',
            component: () => import('../views/ClientViews/ClientView.vue')
          },
          {
            path: 'clientes/registro',
            name: 'Registro Cliente',
            component: () => import('../views/ClientViews/CreateView.vue')
          },
          {
            path: 'clientes/consulta/:uuid',
            name: 'Edición Cliente',
            props: true,
            component: () => import('@/views/ClientViews/EditView.vue')
          },
        ]
      },
      {
        path: 'facturas',
        component: () => import('@/views/InvoiceViews/Index.vue'),
        meta: { title: 'Facturacion', icon: 'mdiAccounts', requiresAuth: true },
        children: [
          //FACTURAS
          {
            path: '',
            name: 'Facturas',
            component: () => import('@/views/InvoiceViews/InvoiceView.vue')
          },
          {
            path: 'registro',
            name: 'Registro Factura',
            component: () => import('@/views/InvoiceViews/CreateView.vue')
          },
          {
            path: 'consulta/:uuid',
            name: 'Edición Factura',
            props: true,
            component: () => import('@/views/InvoiceViews/EditView.vue')
          },
          {
            path: 'preview/:uuid',
            name: 'Vista Factura',
            props: true,
            component: () => import('@/views/InvoiceViews/InvoicePreView.vue')
          },
        ]
      },
      {
        path: 'materializacion',
        component: () => import('@/views/MaterializationView/Index.vue'),
        meta: { title: 'Materializacion', icon: 'mdiAccounts', requiresAuth: true },
        children: [
          //FACTURAS
          {
            path: '',
            name: 'Cotizaciones',
            component: () => import('@/views/MaterializationView/InvoiceView.vue')
          },
          {
            path: 'consulta/:uuid',
            name: 'Consulta cotizacion',
            props: true,
            component: () => import('@/views/MaterializationView/EditView.vue')
          }
        ]
      },
      {
        path: 'requisiciones',
        component: () => import('@/views/RequisitionViews/Index.vue'),
        meta: { title: 'Requisiciones', icon: 'mdiAccounts', requiresAuth: true },
        children: [
          {
            path: '',
            name: 'requisiciones',
            component: () => import('@/views/RequisitionViews/ListView.vue')
          },
          {
            path: 'registro',
            name: 'Registro Requisicion',
            component: () => import('@/views/RequisitionViews/CreateView.vue')
          },
          {
            path: 'consultar/:uuid',
            name: 'Edición Requisicion',
            props: true,
            component: () => import('@/views/RequisitionViews/EditView.vue')
          },
        ]
      },
      {
        path: 'obras',
        component: () => import('@/views/ObrasViews/Index.vue'),
        meta: { title: 'Obras', icon: 'mdiAccounts', requiresAuth: true },
        children: [
          {
            path: '',
            name: 'requisiciones obras',
            component: () => import('@/views/ObrasViews/ListView.vue')
          },
          {
            path: 'registro_requisicion',
            name: 'Registro obras requisiciones',
            component: () => import('@/views/ObrasViews/CreateView.vue')
          },
          {
            path: 'consulta_requisicion/:uuid',
            name: 'Obras Edición',
            props: true,
            component: () => import('@/views/ObrasViews/EditView.vue')
          },
        ]
      },
      {
        path: 'eudania',
        component: () => import('@/views/EudaniaViews/Index.vue'),
        meta: { title: 'Eudania', icon: 'mdiAccounts', requiresAuth: true },
        children: [
          {
            path: '',
            name: 'requisiciones Eudania',
            component: () => import('@/views/EudaniaViews/ListView.vue')
          },
          {
            path: 'registro_requisicion',
            name: 'Registro Eudania requisiciones',
            component: () => import('@/views/EudaniaViews/CreateView.vue')
          },
          {
            path: 'consulta_requisicion/:uuid',
            name: 'Eudania Edición',
            props: true,
            component: () => import('@/views/EudaniaViews/EditView.vue')
          },
        ]
      },
      // {
      //   path: 'asistencias',
      //   component: () => import('@/views/AttendanceViews/index.vue'),
      //   meta: { title: 'Asistencias', icon: 'mdiAccounts', requiresAuth: true },
      //   children: [
      //     {
      //       path: '',
      //       name: 'Lista Asistencia',
      //       component: () => import('@/views/AttendanceViews/ListView.vue')
      //     }
      //   ]
      // },
      {
        path: 'gestion_documental',
        component: () => import('@/views/DocumentManagementView/index.vue'),
        meta: { title: 'Gestion documental', icon: 'mdiAccounts', requiresAuth: true },
        children: [
          {
            path: '',
            name: 'gestion documental',
            component: () => import('@/views/DocumentManagementView/ListView.vue')
          },
        ]
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/LoginViews/LoginView.vue'),
    name: 'Login',
    meta: { title: 'Autenticacion', icon: 'mdiAccounts', requiresAuth: false }
  },
  {
    path: '/logout',
    component: () => import('@/views/LoginViews/LogoutView.vue'),
    name: 'Logout',
    meta: { title: 'Cerrando sesión', icon: 'mdiAccounts', requiresAuth: true }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: { requiresAuth: false },
    component: NotFoundView
  },
]

const router = createRouter({
  scrollBehavior: function () {
    return { x: 0, y: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const store = useCommonStore();
  const items = to.matched.map(route => ({
    text: route.name,
    href: route.path,
  }));

  if (to.matched.some((record) => record.meta.requiresAuth)) {
   if (to.path != '/login' && isAuthenticated()) {
      store.setItems(items);
      next();
    }
    else {
      router.replace({ path: '/login' })
    }
  }
  else {
    store.setItems(items);
    next()
  }
})

export default router
