import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyC9Wo3wHx7QixiOlTf8TDmJZRZzP-vKEEM",
  authDomain: "efs-invoice-admin.firebaseapp.com",
  projectId: "efs-invoice-admin",
  storageBucket: "efs-invoice-admin.appspot.com",
  messagingSenderId: "831558478850",
  appId: "1:831558478850:web:478ed9cbea2e5b3b810b1b",
  measurementId: "G-TVJ8EWP6NX"
};


const app = initializeApp(firebaseConfig);


export default { app }